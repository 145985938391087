import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { SubscribeComponent } from './subscribe/subscribe.component';
import { ProductComponent } from './product/product.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoadingComponent } from './loading/loading.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { EditAddressComponent } from './edit-address/edit-address.component';
import { FieldWithLabelComponent } from './field-with-label/field-with-label.component';
import { AgeRestrictionComponent } from './age-restriction/age-restriction.component';
import { TableComponent } from './table/table.component';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { ButtonModule } from 'primeng/button';
import { PipesModule } from '../pipes/pipes.module';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { PaginatorModule } from 'primeng/paginator';
import { MenuModule } from 'primeng/menu';
import { StockIndicatorComponent } from './stock-indicator/stock-indicator.component';
import { AddReviewComponent } from './add-review/add-review.component';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { RatingModule } from 'primeng/rating';
import { ScrollTopModule } from 'primeng/scrolltop';
import { ImageModule } from 'primeng/image';

@NgModule({
	declarations: [
		HeaderComponent,
		FooterComponent,
		SubscribeComponent,
		ProductComponent,
		LoadingComponent,
		EditAddressComponent,
		FieldWithLabelComponent,
		AgeRestrictionComponent,
		TableComponent,
		StockIndicatorComponent,
		AddReviewComponent,
	],
	imports: [
		CommonModule,
		RouterModule,
		ReactiveFormsModule,
		FormsModule,
		ProgressSpinnerModule,
		TableModule,
		TooltipModule,
		ButtonModule,
		PipesModule,
		AvatarModule,
		AvatarGroupModule,
		PaginatorModule,
		MenuModule,
		InputTextareaModule,
		RatingModule,
		ScrollTopModule,
		ImageModule
	],
	exports: [
		HeaderComponent,
		FooterComponent,
		ProductComponent,
		LoadingComponent,
		FieldWithLabelComponent,
		TableComponent, StockIndicatorComponent
	],
})
export class ComponentsModule { }
