import { Component, Input, Output } from '@angular/core';
import { Table } from 'primeng/table';
import { FieldsType } from 'src/app/enums/fields-type.enum';

@Component({
	selector: 'app-table',
	templateUrl: './table.component.html',
	styleUrls: ['./table.component.scss'],
})
export class TableComponent {
	selected: any = [];
	@Input('columns') columns: any;
	@Input('data') data: any;
	@Input('paginator') paginator: boolean = true;
	@Input('rows') rows: number = 20;
	@Input('showFilter') showFilter: boolean = false;

	@Input('showActions') showActions: boolean = false;

	@Input('onRowClick') onRowClick: Function = () => { };

	@Input('showEditAction') showEditAction: boolean = false;
	@Input('onEdit') onEdit: Function = () => { };

	@Input('showDeleteAction') showDeleteAction: boolean = false;
	@Input('onDelete') onDelete: Function = () => { };

	@Input('showExtraAction') showExtraAction: boolean = false;
	@Input('extraActionToolTip') extraActionToolTip: string = '';
	@Input('extraActionIcon') extraActionIcon: string = '';
	@Input('onExtraAction') onExtraAction: Function = () => { };

	@Input('sortOrder') sortOrder: -1 | 1 = -1;
	@Input('sortField') sortField: string = '';

	@Input('totalRecords') totalRecords: number = 0;
	@Input('lazy') lazy: boolean = false;;
	@Input('onLazyLoad') onLazyLoad: Function = () => { };

	@Output('selectedData') selectedData: any;

	get fieldTypes() {
		return FieldsType;
	}

	exportCSV(table: Table) {
		const columns = JSON.parse(JSON.stringify(this.columns));
		delete columns.Action;
		table.columns = columns;
		table.exportCSV();
	}
}
