<div id="g_id_onload" data-client_id="372616248559-f13et03540vukpsocvi9g3mkabd10nvd.apps.googleusercontent.com" data-context="signin" data-login_uri="https://ganjastan.co.uk/sign-in" data-auto_select="true" data-itp_support="true"></div>

<!-- <p-menu [model]="myAccountItems" #myAccountMenu [popup]="true" appendTo="body"></p-menu> -->
<p-menu [model]="myAccountItems" #myAccountMenu [popup]="true" appendTo="body" id="myAccountMenu" styleClass="mt-1">
	<ng-template pTemplate="item" let-item>
		<a *ngIf="item?.routerLink" [attr.tabindex]="-1" class="flex items-center gap-1 p-3 cursor-pointer" [routerLink]="item.routerLink">
			<span class="material-symbols-rounded">{{ item.icon }}</span>
			<span> {{ item.label }}</span>
		</a>
		<a *ngIf="item?.command" [attr.tabindex]="-1" class="flex items-center gap-1 p-3 cursor-pointer" [attr.href]="item.url" (click)="(item.command)">
			<span class="material-symbols-rounded">{{ item.icon }}</span>
			<span> {{ item.label }}</span>
		</a>
	</ng-template>
</p-menu>

<div class="flex flex-col items-start">
	<div class="flex w-full">
		<div class="w-[200px] bg-white p-1 sm:p-2" routerLink="/">
			<img src="assets/images/logo.jpg" alt="" class="w-full cursor-pointer" />
		</div>

		<div class="flex-col justify-center hidden w-full pt-2 pl-0 pr-4 lg:flex bg-secondary">
			<div class="flex items-center justify-between gap-4 ml-[20px] flex-1">
				<!-- <div class="flex items-center gap-8 font-bold text-white">
					<a routerLink="/" class="menu-item" *ngIf="!isLogin">
						Sign In Tester
					</a>
					<div class="border-r-[2px] border-white w-[1px] h-[22px] -mx-3 hidden sm:block"></div>
					<a routerLink="/" class="menu-item" *ngIf="!isLogin">
						Sign In Breeder
					</a>
				</div> -->
				<nav class="flex items-center justify-end w-full">
					<!-- <a href="mailto:info@starsandstripesco.co.uk"
									class="pr-4 text-white border-r icon-item border-secondary-dark">
									<i class="far fa-envelope"></i>
									info@starsandstripesco.co.uk
								</a> -->

					<!-- <div class="flex w-auto gap-4 ml-4 font-bold text-white">
									<span class="font-bold uppercase text-swhite">Beta Testing</span>
								</div> -->

					<div class="flex items-center gap-8 font-bold text-white">
						<!-- <a routerLink="/" class="menu-item"> Home </a> -->
						<a routerLink="/suppliers" class="menu-item"> Search Breeders </a>
						<div class="border-r-[2px] border-white w-[1px] h-[22px] -mx-3 hidden sm:block"></div>
						<a routerLink="/forum" [queryParams]="{ref:'New Replies'}" class="menu-item"> Forums </a>
						<div class="border-r-[2px] border-white w-[1px] h-[22px] -mx-3 hidden sm:block"></div>
						<!-- <a routerLink="/blog" class="menu-item">
										Blog
									</a> 
									
									<a routerLink="/about-us" class="menu-item"> About Us </a> -->

						<a routerLink="/cart" class="relative flex items-center menu-item">
							Cart
							<span class="bg-white text-black-light rounded-full w-[20px] h-[20px] text-center text-sm ml-1">{{ cartItemsCount }}</span>
						</a>

						<!-- <a routerLink="/contact-us" class="menu-item"> Contact </a> 
									
										<a routerLink="/faq" class="menu-item">
										FAQ
									</a> -->

						<a routerLink="/sign-in" class="menu-item" *ngIf="!isLogin"> Sign In User </a>

						<!-- <a routerLink="/sign-up" class="menu-item" *ngIf="!isLogin">
										Sign Up
									</a> -->

						<div class="user-profile" *ngIf="isLogin">
							<!-- <span>Hello, {{ userPersonalDetails?.name }}</span> -->
							<div class="menu-item account-profile" (click)="myAccountMenu.toggle($event)">
								My Account
								<span class="material-symbols-rounded">expand_more</span>
							</div>

							<!-- <div id="profile-menu" class="animate__fadeInDown">
											<div class="profile-item" routerLink="/settings">
												<i class="fa-solid fa-user-tie"></i>
												Profile & Settings
											</div>
											<div class="profile-item" routerLink="/order-history">
												<i class="fa-solid fa-clock-rotate-left"></i>
												Orders
											</div>
											<div class="profile-item" (click)="signOut()">
												<i class="fa-solid fa-arrow-right-from-bracket"></i>
												Sign Out
											</div>
										</div> -->
						</div>

						<div class="border-r-[2px] border-white w-[1px] h-[22px] -mx-3 hidden sm:block"></div>
						<a href="https://twitter.com/StarStripesSeed" class="social-menu-item" target="_blank">
							<i class="fab fa-twitter"></i>
						</a>
						<div class="border-r-[2px] border-white w-[1px] h-[22px] -mx-3 hidden sm:block"></div>
						<a href="https://www.instagram.com/starsandstripesseed/" class="social-menu-item" target="_blank">
							<i class="fab fa-instagram"></i>
						</a>

						<!-- <a class="social-menu-item mobile-side-nav-button" routerLink="/cart">
										<i class="fa-solid fa-cart-shopping"></i>
									</a> -->

						<a class="block mobile-side-nav-button md:hidden" (click)="openSideMenu()">
							<i class="fas fa-bars"></i>
						</a>
					</div>
				</nav>
			</div>

			<div class="hidden sm:flex w-full justify-between bg-white -ml-1 pt-2 pb-2 pr-4 mr-2 border-r-[30px] border-secondary">
				<div class="flex items-center gap-4 ml-[20px]">
					<span class="text-[14px] font-normal cursor-default">Our Brands</span>
					<div class="border-r-[2px] border-[#d3d3d3] w-[1px] h-[14px] hidden sm:block"></div>
					<div>
						<span *ngFor="let b of brands">
							<a [routerLink]="['/brand-products', b.id]" class="quick-item menu-item" *ngIf="b.position == 'L'">
								<span class="text-base font-medium"> {{ b.brand_name }} </span>
							</a>
						</span>
					</div>
				</div>
				<div class="flex justify-end">
					<span *ngFor="let b of brands" class="mr-4">
						<a [routerLink]="['/brand-products', b.id]" class="quick-item border-r align-middle border-[#e1e1e1] last:border-r-0" *ngIf="b.position == 'R'">
							<!-- <img [alt]="b.brand_name" [src]="imageUrl + b.brand_logo" class="px-5 h-14"> -->
							<span class="text-base font-medium"> {{ b.brand_name }} </span>
						</a>
					</span>
				</div>
			</div>
		</div>

		<div class="flex flex-col items-end justify-center w-full pt-2 pl-4 pr-4 lg:hidden bg-secondary">
			<div class="flex items-center justify-between w-full gap-4 text-white">
				<div class="flex items-center gap-4">
					<a class="relative flex items-center social-menu-item mobile-side-nav-button" [routerLink]="'/suppliers'"> Breeders </a>
					<div class="border-r-[2px] border-white w-[1px] h-[14px]"></div>
					<a class="relative flex items-center social-menu-item mobile-side-nav-button" [routerLink]="'/forum'" [queryParams]="{ref:'New Replies'}"> Forums </a>
				</div>

				<div class="flex gap-4">
					<a class="relative flex items-center social-menu-item mobile-side-nav-button" routerLink="/cart">
						<div class="relative flex items-center">
							<i class="fa-solid fa-cart-shopping"></i>
							<span class="bg-white text-black-light rounded-full w-[20px] h-[20px] text-center text-sm ml-1">
								{{ cartItemsCount }}
							</span>
						</div>
					</a>

					<a class="block mobile-side-nav-button lg:hidden" (click)="openSideMenu()">
						<i class="fas fa-bars"></i>
					</a>
				</div>
			</div>
		</div>

		<nav id="mobile-nav" class="mobile-nav">
			<button class="close-button" (click)="closeSideMenu()">
				<i class="fas fa-times"></i>
			</button>

			<div class="side-menu-items">
				<!-- <a routerLink="/categories" class="menu-item">
					Cannabis Seeds
				</a> -->

				<!-- <a routerLink="/" class="menu-item"> Cannabis Seeds </a> -->

				<!-- <a routerLink="/blog" class="menu-item">
					Blog
				</a> -->

				<!-- <a routerLink="/suppliers" class="menu-item">
					Breeders
				</a>
				<a routerLink="/forum" class="menu-item">
					Forums
				</a> -->

				<!-- <a routerLink="/about-us" class="menu-item"> About Us </a> -->

				<a [routerLink]="['/cart']" class="menu-item"> Cart - {{ cartItemsCount }} Items </a>

				<!-- <a routerLink="/contact-us" class="menu-item"> Contact </a> -->

				<!-- <a routerLink="/faq" class="menu-item">
					FAQ
				</a> -->

				<a [routerLink]="['/settings']" class="menu-item" *ngIf="isLogin"> Profile & Settings </a>

				<a [routerLink]="['/order-history']" class="menu-item" *ngIf="isLogin"> Orders </a>

				<a (click)="signOut(); closeSideMenu()" class="menu-item" *ngIf="isLogin"> Sign Out </a>

				<a [routerLink]="['/sign-in']" class="menu-item" *ngIf="!isLogin"> Sign In User </a>

				<a [routerLink]="['/tester-sign-in']" class="menu-item" *ngIf="!isLogin"> Sign In Tester </a>
				<div class="border-r-[2px] border-white w-[1px] h-[22px] -mx-3 hidden sm:block"></div>
				<a [routerLink]="['/become-supplier']" class="menu-item" *ngIf="!isLogin"> Sign In Breeder </a>
				<span class="divider"></span>

				<ng-container *ngFor="let b of brands">
					<a [routerLink]="['/brand-products', b.id]" class="quick-item menu-item mb-2 rounded-md bg-[#74b3c0]" *ngIf="b.position == 'L'">
						{{ b.brand_name }}
					</a>
				</ng-container>
				<ng-container *ngFor="let b of brands">
					<a [routerLink]="['/brand-products/', b.id]" class="mb-2 rounded-md quick-item menu-item" *ngIf="b.position == 'R'">
						{{ b.brand_name }}
					</a>
				</ng-container>
				<span class="divider"></span>
			</div>

			<div class="social-menu">
				<a href="https://twitter.com/StarStripesSeed" class="social-menu-item" target="_blank">
					<i class="fab fa-twitter"></i>
				</a>
				<a href="https://www.instagram.com/starsandstripesseed/" class="social-menu-item" target="_blank">
					<i class="fab fa-instagram"></i>
				</a>
			</div>
		</nav>
	</div>
	<span class="hidden pl-4 text-xs italic font-medium text-center underline uppercase cursor-pointer lg:text-sm lg:block text-gray-dark" [routerLink]="['contact-us']" [queryParams]="{ ref: 'beta' }">
		Beta <br />
		Testing
	</span>
	<span class="block pl-4 text-xs italic font-medium text-center underline uppercase cursor-pointer lg:text-sm lg:hidden text-gray-dark" [routerLink]="['contact-us']" [queryParams]="{ ref: 'beta' }"> Beta Testing </span>
</div>